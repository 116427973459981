import styled from "styled-components";

export const FormContainer = styled.form`
     width:min(25rem , 95%) ;
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    z-index: 1000;
    h3 {
      text-align: center;
    }
    .Input{
      display: block;
      width: 100%;
      padding:.3rem .8rem;
      margin-bottom: 1rem;
      border-radius: .25rem;
      border: 1px solid black;
      resize: none;
    }
    .TextArea{
       height: 9rem;
    }
    .CheckBox{
      display: flex;
      align-items: baseline;
      gap: .8rem ;
    }
    button{
      border: none;
      width: 100%;
      background-color: var(--button-color , #0d6efd);
      color: white;
      border-radius:.4rem;
      padding: .45rem;
    }
    .EmailsButton{
      background-color: var(--button-color , #0d6efd);
      color: white;
      border-radius:.4rem;
      padding: .45rem;
    }
    .ReciveEmails{
      margin: 0;
    }
    .Reciver{
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;
      button{
        width: fit-content;
        padding:.2rem;
      }
    }
    .Emails{
      p{
        color: black;
      }
      .SvgWrapper{
        width:1rem;
        height:1rem;
        svg{
          width: 100%;
          height: 100%;
        }
      }
    }
`