/** @format */

import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const FloorPlanCards = styled(Link)`
  padding-block: 1rem;
  text-decoration: none;
  color: #13131a;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  .price {

    h1 {
      font-size: 1.5rem;
      font-size: clamp(1.2rem, 1rem +2vw, 2rem);
      margin:0;
    }
  }

  p {
    margin: 0;
  }
  .ImageContainer {
    width: 5rem;
    height: 5rem;
    background-color: gray;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .Content {
    .details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .Detail {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
    }
  }
  .arrow {
    margin-left: auto;
  }

  @media screen and (max-width: 498px) {
    .Content {
      .details {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
`;
