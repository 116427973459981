/** @format */

import React from 'react';
import {
    Container,
    FooterLinkContainer,
    FooterLink,
    FooterNlink,
    Button,
    ContactUs
} from './Footer.style';
import Facebook from './Images/facebook.svg'
import Twitter from './Images/twitter.svg'
import LinkedLin from './Images/linkedin.png'
import Instagram from './Images/instagram.png'
import Phone from './Images/Phone.png'
import Email from './Images/Email.svg'
export default function Footer() {
    return (
        <Container>
            <FooterLinkContainer>
                <div className="Buy-sell">
                    <p>buy & sell</p>
                    <FooterLink>Sell</FooterLink>
                    <FooterLink>Find Home Value</FooterLink>
                    <FooterLink>Browse Home</FooterLink>
                    <FooterLink>Sold Homes</FooterLink>
                    <FooterLink>Cash Offer</FooterLink>
                    <FooterLink>Buy</FooterLink>
                    <FooterLink>New Construction</FooterLink>
                    <FooterLink>Pricing</FooterLink>
                    <FooterLink>Down Payment</FooterLink>
                </div>
                <div className="Investment">
                    <p>INVESTMENT</p>
                    <FooterLink>Co-Investment</FooterLink>
                </div>
                <div className="Company">
                    <p>Company</p>
                    <FooterLink>About</FooterLink>
                    <FooterLink>Contact Us</FooterLink>
                    <FooterLink>Vendors</FooterLink>
                    <FooterLink>Careers</FooterLink>
                    <FooterLink>Privacy Policy</FooterLink>
                    <FooterLink>Terms & Conditions</FooterLink>
                </div>
                <div className="Learn">
                    <p>Learn</p>
                    <FooterLink>Seller FAQs</FooterLink>
                    <FooterLink>Buyer FAQs</FooterLink>
                    <FooterLink>Blogs</FooterLink>
                </div>
                <div className="Location">
                    <p>Locations</p>
                    <FooterLink>Greater Toronto Area</FooterLink>
                    <FooterLink>Region of Peel</FooterLink>
                    <FooterLink>Region of Hamilton</FooterLink>
                    <FooterLink>Region of Waterloo</FooterLink>
                    <FooterNlink>View All Locations</FooterNlink>
                    <Button>
                        <div className="warapper">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="16"
                                viewBox="0 0 12 16"
                                fill="none">
                                <path
                                    d="M6.00006 0.5C2.69181 0.5 6.10835e-05 3.19175 6.10835e-05 6.49625C-0.0216889 11.33 5.77206 15.338 6.00006 15.5C6.00006 15.5 12.0218 11.33 12.0001 6.5C12.0001 3.19175 9.30831 0.5 6.00006 0.5ZM6.00006 9.5C4.34256 9.5 3.00006 8.1575 3.00006 6.5C3.00006 4.8425 4.34256 3.5 6.00006 3.5C7.65756 3.5 9.00006 4.8425 9.00006 6.5C9.00006 8.1575 7.65756 9.5 6.00006 9.5Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <p>Map Search</p>
                    </Button>
                </div>
            </FooterLinkContainer>
            <ContactUs>
                <p>Conatact us</p>
                <div className="socialMedia">
                    <img src={Facebook} alt="Facebook" />
                    <img src={Twitter} alt="Twitter" />
                    <img src={LinkedLin} alt="LinkedLin" />
                    <img src={Instagram} alt="Instagram" />
                </div>
                <div className="Contact_info">
                    <div className="contact">
                        <img src={Phone} alt="PhoneIcon" />
                        <p>905-567-0200</p>
                    </div>
                    <div className="contact">
                        <img src={Email} alt="EmailIcon" />
                        <p>info@BuilderMatch.com</p>
                    </div>
                </div>
                <p className="CopyRight">
                    © 2021 BuilderMatch.com. All rights reserved.
                </p>
                <p className="Act">
                    BuilderMatch Realty. Brokerage, is fully registered under the Real Estate and Business brokerage, Act, 2002 of Ontario.
                </p>
            </ContactUs>
        </Container>
    );
}
