/** @format */

import React from 'react';

import { Conatainer, CardContainer } from './RedBuyersHelpSection.style';
import PreConstruction from './Images/PreConstruction.svg';
import InventroyHome from './Images/InventoryHome.svg';
import AssignmentSales from './Images/AssignmentSales.svg';
export default function BuilderMatchHelpSection() {
  return (
    <Conatainer>
      <div className="Content">
        <h1>See How BuilderMatch Can Help</h1>
        <p>With Builder Deals</p>
      </div>
      <CardContainer>
        <div className="Card">
          <div className="icon">
            <img
              src={PreConstruction}
              alt=""
            />
          </div>
          <h1>Pre-Construction Homes</h1>
          <p>
            Pre-construction homes are properties available for purchase before
            or during early construction, typically taking 3-4 years to build,
            allowing buyers time for down payments and customization
          </p>
        </div>
        <div className="Card">
          <div className="icon">
            <img
              src={InventroyHome}
              alt=""
            />
          </div>
          <h1>Inventory Homes</h1>
          <p>
            Inventory homes are pre-built properties offered by home builders,
            available for immediate purchase and quick move-in. These homes
            often include popular upgrades and can be physically viewed before
            making an offer.
          </p>
        </div>
        <div className="Card">
          <div className="icon">
            <img
              src={AssignmentSales}
              alt=""
            />
          </div>
          <h1>Assignment Sales</h1>
          <p>
            Assignment sales in new homes involve the original buyer selling
            their purchase contract to another buyer before the home is
            officially owned. The new buyer, often benefiting from a property
            that has appreciated in value
          </p>
        </div>
      </CardContainer>
    </Conatainer>
  );
}
