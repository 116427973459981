import styled from "styled-components";

export const SearchContainer = styled.div`
    position: relative;
    margin-left: 1rem;
    width: min(25rem, 95%);
    .Cities {
      position: absolute;
      height: 14rem;
      z-index: 100;
      overflow-y: scroll;
      width: 100%;
      background-color: #ffff;
      border-radius: 1.25rem;
      border: 1px solid black;
      .City {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0 0.75rem;
        border-bottom: 1px solid black;
        p {
          cursor: pointer;
        }
        svg {
          width: 1rem;
        }
      }
    }
`
export const SerchContainer = styled.div`
  border-radius: 1.25rem;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.9rem;
  .icon {
    width: 1.125rem;
    svg {
      width: 100%;
    }
  }
  input {
    flex: 1;
    border: none;
    outline: none;
    margin-left: 0.75rem;
  }
  button {
    border-radius: 0.9375rem;
    background: #0061df;
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
    border: none;
    padding: 0.75rem;
    color: #ffffff;
  }
`;