export function PropertySetImageBanner(Data, setCoverPhotos) {
    const homeType = Data?.home_type;
    let planProperties = []
    if (Data?.plan_properties) {
        planProperties = Data?.plan_properties[0]?.property_cover_image;
    }

    const elevationImage = Data?.property_elevation_image;
    const properties = ["Detached", "Semi-detached", "Town House"]

    if (!properties.includes(homeType) && planProperties?.length) {
        setCoverPhotos(planProperties)
    } else if (properties.includes(homeType) && elevationImage?.length) {
        setCoverPhotos(elevationImage)
    } else {
        setCoverPhotos([{ file_url: "/assets/images/no-image-available.png" }])
    }
}