/** @format */

import Slider from 'react-slick';
import styled from 'styled-components';

export const NavContainer = styled(Slider)`
  position: sticky;
  top: 100px;
  margin-bottom: 2rem;
  /* display: flex;
  gap: 0.25rem; */
  width: 100%;
  /* width:min(67.5rem,100%) ; */
  /* overflow-x:auto; */
  backdrop-filter: blur(10px);
  .slick-slide {
    padding: 0 10px;
    overflow: hidden;
    /* box-sizing: border-box; */
  }
`;

export const Tab = styled.a`
  padding: 0.625rem 0rem 0.625rem 0rem;
  border-radius: 0.25rem;
  background: #ededed;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ $active }) => $active && '#0061df'};
  .svg_wrapper {
    text-align: center;
    svg {
      path {
        fill: ${({ $active }) => $active && '#FFFFFF'};
      }
      &.no_fill {
        path {
          stroke: ${({ $active }) => $active && '#FFFFFF'};
          fill: none;
        }
      }
    }
  }
  .sub_heading {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #323232;
    text-align: center;
    margin: 0;
    color: ${({ $active }) => $active && '#FFFFFF'};
  }
  &:hover {
    .svg_wrapper {
      svg {
        path {
          fill: ${({ $active }) => !$active && '#0061df'};
        }
        &.no_fill {
          path {
            stroke: ${({ $active, theme: { colors } }) =>
              !$active && '#0061df'};
            fill: none;
          }
        }
      }
    }
    .sub_heading {
      color: ${({ $active }) => !$active && '#0061dfs'};
    }
  }
`;
