/** @format */

import React, { useRef } from 'react';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider, MediaPlayerInstance, useStore } from '@vidstack/react';
import {
    defaultLayoutIcons,
    DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import { Poster } from '@vidstack/react';
import { VideoContainer } from './VideoPlayer.style';
import { PlayButton } from '@vidstack/react';
import { PauseIcon, PlayIcon } from '@vidstack/react/icons';
export default function VideoPlayer({ Data }) {
    const player = useRef(null)
    const { playing } = useStore(MediaPlayerInstance, player);
    return (
        <>
            {Data.virtual_tour &&
                <VideoContainer playerCheck={playing}>
                    <h1 id='Virtual_Tour'>Virtual Tour</h1>
                    <MediaPlayer
                        ref={player}
                        title="Virtual Tour"
                        // src="https://dev.redbuyers.com/assets/temp/video/first/pexels-curtis-adams-15887132%20(1440p).mp4"
                        src={Data?.plan_properties[0]?.virtual_tour}
                    >
                        <MediaProvider />
                        <DefaultVideoLayout icons={defaultLayoutIcons} />
                        <PlayButton className="vds-button custom_button">
                            <PlayIcon className="play-icon vds-icon" />
                            <PauseIcon className="pause-icon vds-icon" />
                        </PlayButton>
                        <Poster
                            className="vds-poster"
                            src={`https://www.redbuyers.com/${Data.thumbnails[0]}`}
                            alt="Girl walks into campfire with gnomes surrounding her friend ready for their next meal!"
                        />
                    </MediaPlayer>

                </VideoContainer>
            }

        </>

    );
}
