/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  padding: 0.3rem;
  .SortingContainers {
    display: flex;
    gap: 1rem;
    .SortingContainer {
      position: relative;
    }
  }
  .Filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    width: fit-content;
    border-radius: 0.625rem;
    padding: 0.94rem;
    p {
      color: #0061df;
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 600;
    }
    &:hover {
      cursor: pointer;
      background: #f2f2f2;
    }
  }
  .FilterContainer {
    border-radius: 0rem 0rem 0.9375rem 0.9375rem;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    width: 35rem;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    h1 {
      color: #6e6e73;
      font-family: Inter;
      font-size: 0.875rem;
      font-weight: 500;
    }
    .Filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .Heading {
        color: #0061df;
        font-family: Inter;
        font-size: 1rem;
        font-size: clamp(0.75rem, 1rem + 1vw, 1.2rem);
        font-weight: 500;
      }
    }
    .Types {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.2rem;
      margin-top: 0.75rem;
    }
    .Combo-bedBath,
    .PriceRange,
    .BuildingStatus,
    .HouseType {
      margin-top: 2rem;
    }
    .Combo-bedBath {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .Reset {
      cursor: pointer;
    }
  }
  .SortBy {
    border-radius: 0rem 0rem 0.9375rem 0.9375rem;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    width: min(30rem, 95%);
    position: absolute;
    top: 100%;
    left: 0;
    width: max-content;
    z-index: 1000;
  }
  .ShareButton {
    border-radius: 0.9375rem;
    background: #0061df;
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
    border: none;
    padding: 0.75rem;
    color: #ffffff;
    margin-left: auto;
    margin-right: 3rem;
    cursor: pointer;
  }
  @media screen and (max-width: 1040px) {
    align-items: flex-start;
    flex-direction: column;
  }
  @media screen and (max-width: 510px) {
    gap: 0.65rem;
    .FilterContainer {
      width: 95vw;
      .Combo-bedBath {
        flex-direction: column;
      }
    }
    .SortBy {
      left: -100%;
      width: 90vw;
    }
  }
  @media screen and (min-width: 1292px) {
    .FilterContainer {
      width: 52rem;
    }
  }
`;

export const Types = styled.button`
  border-radius: 0.3125rem;
  background: #f2f2f2;
  background-color: ${(props) => (props.check ? '#0061df' : '#f2f2f2')};
  color: ${(props) => (props.check ? '#fff' : '#0061df')};
  font-family: Inter;
  font-size: 1.125rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  border: none;
  outline: none;
  padding: 0.31rem 1.25rem;
`;

export const PriceSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.7rem;
`;

export const SortByElements = styled.button`
  border-radius: 0.3125rem;
  background: #f2f2f2;
  color: #0061df;
  font-family: Inter;
  font-size: 1.125rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  border: none;
  outline: none;
  padding: 0.31rem 1.25rem;
`;

export const ShareContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  display: block;
  z-index: 1000;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;

`;
