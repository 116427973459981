/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  /* height: 100vh; */
  .main {
    width: min(100rem, 95%);
    height: calc(100vh - 4rem);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.6rem;
    .leftSection {
      flex: 1;
      h1 {
        color: #13131a;
        font-family: Inter;
        font-size: clamp(1.2rem, 1rem + 2vw, 4rem);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .rightSection {
      flex: 1;
      margin: 0 auto;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 560px) {
    .main {
      flex-direction: column-reverse;
      .rightSection {
        margin-top: 3rem;
      }
    }
  }
`;

export const SearchContainer = styled.div`
  border-radius: 1.25rem;
  max-width: 40rem;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin-top: 1rem;
  .icon {
    width: 2rem;
    height: 2rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  input {
    flex: 1;
    border: none;
    outline: none;
    margin-left: 0.75rem;
  }
  button {
    border: 0;
    border-radius: 0.9375rem;
    background: #0061df;
    box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
    padding: 1.2rem;
    color: #ffffff;
  }
`;

export const Vectors = styled.img`
  position: absolute;
  bottom: 5rem;
  width: 100%;
  z-index: -100;
`;
