/** @format */

import React, { useEffect, useState } from 'react';
import {
  Container,
  LeftContainer,
  RightContainer,
  OverView,
  Map,
  Amenities,
  PropertyDetails,
  Appoinment,
  AvailableFloorPlans,
  AppointmentContainer,
  DepositePremium,
  DepositeStructure,
  MobileDepositePremium,
} from './Details.style';
import BusStand from './Images/fluent-emoji_bus-stop.svg';
import Banks from './Images/fluent-emoji_bank.svg';
import Cinemas from './Images/fluent-emoji_movie-camera.svg';
import Parks from './Images/fluent-emoji_evergreen-tree.svg';
import Shopping from './Images/fluent-emoji_shopping-bags.svg';
import Food from './Images/fluent-emoji_pot-of-food.svg';
import Hospital from './Images/fluent-emoji_medical-symbol.svg';
import Attractions from './Images/fluent-emoji_ferris-wheel.svg';
import Sunset from './Images/fluent-emoji_sunset.svg';
import BulletPoints from './Images/BulletsPoints.svg';
import House from './Images/fluent-emoji_house-with-garden.svg';
import MoneyBag from './Images/fluent-emoji_money-bag.svg';
import MoneyWithWings from './Images/fluent-emoji_money-with-wings.svg';
import HomeWithPeople from './Images/HomeWihtPepole.png';
import FloorPlanCard from './Components/FloorPlan/FloorPlanCard.component';
import AppointmentForm from '../AppointmentForm/AppointmentForm.component';
import VideoPlayer from '../VideoPlayer/VideoPlayer.compoent';
import NearBySchool from './Components/NearBySchool/NearBySchool.component';
import FeatureFacts from './Components/Feature&Facts/Feature_Facts.component';
import { ConvertStringToHtml } from '../../utils/New_construction_details/ConvertDepositeToHtml';
import { DepositeDescription } from '../../utils/New_construction_details/DepositeDescription';
import { DifferentModel } from '../../utils/New_construction_details/DifferentModel';
import NavbarDetail from './Components/Navbar/navbardetails.component';
// utils
import { Propertyname } from '../../utils/New_construction_details/customePropertyName';

export default function Details({ Data }) {
  const [readMore, setReadMore] = useState(true);
  const [appointment, setAppointment] = useState(false);
  const [depositeDescriptoin, setDescription] = useState();
  const [differentModels, setDifferentModel] = useState([]);
  const handleRedmore = () => {
    setReadMore(!readMore);
  };

  useEffect(() => {
    setDescription(DepositeDescription(Data.property));
  }, []);
  useEffect(() => {
    setDifferentModel(DifferentModel(Data));
  }, []);
  return (
    <>
      <Container>
        <LeftContainer>
          <NavbarDetail />
          <OverView
            id="Overview"
            Check={readMore}>
            <h1>Why {Propertyname(Data?.property)} ?</h1>
            <div className="InfoContainer">
              <div className="Info">
                <h1>Type</h1>
                <h3>
                  {Data?.property.home_type === null
                    ? 'No data'
                    : Data?.property.home_type}
                </h3>
              </div>
              <div className="Info">
                <h1>Bedroom</h1>
                <h3>
                  {Data?.property.bedroom === null ? 0 : Data?.property.bedroom}
                </h3>
              </div>
              <div className="Info">
                <h1>Square Foot</h1>
                <h3>
                  {Data?.property.sqft_area === null
                    ? 'No Data'
                    : Data?.property.sqft_area}
                </h3>
              </div>
              <div className="Info">
                <h1>Bathroom</h1>
                <h3>
                  {Data?.property.bathroom === null
                    ? 0
                    : Data?.property.bathroom}
                </h3>
              </div>
              <div className="Info">
                <h1>Garage</h1>
                <h3>
                  {Data?.property.garage === null ? 0 : Data?.property.garage}
                </h3>
              </div>
              <div className="Info">
                <h1>Status</h1>
                <h3>
                  {Data?.property.building_status === null
                    ? 'No data'
                    : Data?.property.building_status}
                </h3>
              </div>
              <div className="Info">
                <h1>Age</h1>
                <h3>No Data</h3>
              </div>
              <div className="Info">
                <h1>Frontage</h1>
                <h3>No Data</h3>
              </div>
            </div>
            {Data?.property.plan_properties[0].description && (
              <div
                className="Description"
                dangerouslySetInnerHTML={{
                  __html: Data?.property.plan_properties[0].description,
                }}></div>
            )}
            {Data?.property.plan_properties[0].description && (
              <div
                className="Wrapper"
                onClick={handleRedmore}>
                <p>Read more about the property</p>

                <svg
                  className="SvgWrapper"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none">
                  <path
                    d="M0 0L5 5L10 0H0Z"
                    fill="#323232"
                  />
                </svg>
              </div>
            )}
          </OverView>
          <Map id="Map">
            <h1>Explore Neighborhood - Map View</h1>
            <div className="Container">
              <div className="leftSection">
                <iframe
                  title="Map"
                  src={`https://maps.google.com/maps?q= ${Data?.property.street_address} &hl=en&z=15&output=embed&`}
                  allowfullscreen="allowfullscreen"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  style={{ border: '0px' }}></iframe>
              </div>
              <div className="rightSection">
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={BusStand}
                      alt=""
                    />
                  </div>
                  <p>Commute</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Banks}
                      alt=""
                    />
                  </div>
                  <p>Banks</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Cinemas}
                      alt=""
                    />
                  </div>
                  <p>Cinemas</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Parks}
                      alt=""
                    />
                  </div>
                  <p>Parks</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Shopping}
                      alt=""
                    />
                  </div>
                  <p>Shopping</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Food}
                      alt=""
                    />
                  </div>
                  <p>Food</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Hospital}
                      alt=""
                    />
                  </div>
                  <p>Hospitals</p>
                </div>
                <div className="BulletPoints">
                  <div className="wrapper">
                    <img
                      src={Attractions}
                      alt=""
                    />
                  </div>
                  <p>Attractions</p>
                </div>
              </div>
            </div>
          </Map>
          {Data?.property.plan_properties[0].amenities && (
            <Amenities>
              <h1>Amenities</h1>
              <div className="Container">
                {Data?.property.plan_properties[0].amenities?.map((data, i) => (
                  <div
                    className="Amenities"
                    key={i}>
                    <div className="wrapper">
                      <img
                        src={Sunset}
                        alt=""
                      />
                    </div>
                    <p>{data}</p>
                  </div>
                ))}
              </div>
            </Amenities>
          )}
          {/* <NearBySchool /> */}
          <PropertyDetails id="Property_Details">
            <h1>Property Details</h1>
            <div className="DetailsContainer">
              <div className="Details">
                <div className="Wrapper">
                  <img
                    src={BulletPoints}
                    alt="BulletPoints"
                  />
                </div>
                <p>
                  Building Type :{' '}
                  <span>
                    {' '}
                    {Data?.property.home_type === null
                      ? 'N/A'
                      : Data?.property?.home_type}
                  </span>
                </p>
              </div>
              <div className="Details">
                <div className="Wrapper">
                  <img
                    src={BulletPoints}
                    alt="BulletPoints"
                  />
                </div>
                <p>
                  Selling Status :{' '}
                  <span>
                    {' '}
                    {Data?.property.is_active === 1 ? 'Active' : 'No Active'}
                  </span>
                </p>
              </div>
              <div className="Details">
                <div className="Wrapper">
                  <img
                    src={BulletPoints}
                    alt="BulletPoints"
                  />
                </div>
                <p>
                  Building Status :{' '}
                  <span>
                    {' '}
                    {Data?.property?.plan_properties[0]?.building_status ===
                    null
                      ? 'N/A'
                      : Data?.property?.plan_properties[0]?.building_status}
                  </span>
                </p>
              </div>
              <div className="Details">
                <div className="Wrapper">
                  <img
                    src={BulletPoints}
                    alt="BulletPoints"
                  />
                </div>
                <p>
                  Builder(s) :{' '}
                  <span>
                    {' '}
                    {Data?.property?.plan_properties[0]?.seller === null
                      ? 'N/A'
                      : Data?.property?.plan_properties[0]?.seller}
                  </span>
                </p>
              </div>
              <div className="Details">
                <div className="Wrapper">
                  <img
                    src={BulletPoints}
                    alt="BulletPoints"
                  />
                </div>
                <p>
                  Architect(s) :{' '}
                  <span>
                    {' '}
                    {Data?.property?.plan_properties[0]?.architect === null
                      ? 'N/A'
                      : Data?.property?.plan_properties[0]?.architect}
                  </span>
                </p>
              </div>
              <div className="Details">
                <div className="Wrapper">
                  <img
                    src={BulletPoints}
                    alt="BulletPoints"
                  />
                </div>
                <p>
                  Basement :{' '}
                  <span>
                    {' '}
                    {Data?.property.basement === null
                      ? 'N/A'
                      : Data?.property.basement}
                  </span>
                </p>
              </div>
              <div className="Details">
                <div className="Wrapper">
                  <img
                    src={BulletPoints}
                    alt="BulletPoints"
                  />
                </div>
                <p>
                  Completed In :{' '}
                  <span>
                    {' '}
                    {Data?.property?.plan_properties[0].completed_at === null
                      ? 'N/A'
                      : Data?.property?.plan_properties[0].completed_at}
                  </span>
                </p>
              </div>
            </div>
          </PropertyDetails>
          <MobileDepositePremium>
            {Data?.property.elevation_premium && (
              <div className="premium_section">
                <h4>Elevation Premium</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: ConvertStringToHtml(
                      Data?.property.elevation_premium
                    ),
                  }}></div>
              </div>
            )}
            {Data?.property.description && (
              <div className="premium_section">
                <h4>Lot Premium</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: ConvertStringToHtml(Data?.property.description),
                  }}></div>
              </div>
            )}
          </MobileDepositePremium>
          {differentModels.length > 0 && (
            <AvailableFloorPlans>
              <h1>Different Models</h1>
              <div className="FloorPlansContainer">
                {differentModels?.map((data) => (
                  <FloorPlanCard
                    key={data.id}
                    Data={data}
                  />
                ))}
              </div>
            </AvailableFloorPlans>
          )}
          <DepositeStructure>
            <h2>Deposite Structure</h2>
            <div
              className="DepositeStructure"
              dangerouslySetInnerHTML={{ __html: depositeDescriptoin }}></div>
          </DepositeStructure>
          <FeatureFacts Data={Data?.property} />
          <VideoPlayer Data={Data?.property} />
        </LeftContainer>
        <RightContainer>
          <Appoinment>
            <div className="animation">
              <div
                className="wrapper"
                onClick={() => setAppointment(!appointment)}>
                <img
                  src={House}
                  alt="House"
                />
              </div>
            </div>
            <div className="content">
              <p>
                Hurry Up! <br /> Click here to schedule an <br /> appoinment!
              </p>
            </div>
          </Appoinment>
          {/* <div className="ACard">
                        <div className="Content">
                            <h1>Down Payment Assistance for your Home?</h1>
                            <p>Transform your down payment into
                                20% <br /> or more with the help from BuilderMatch</p>
                        </div>
                        <img src={MoneyWithWings} alt="MoneyWithWings" />
                    </div>
                    <div className="ACard">
                        <div className="Content">
                            <h1>Co-investment at 1/8th Cost!</h1>
                            <p>Min ROI 25%. Click to learn more!</p>
                        </div>
                        <img src={HomeWithPeople} alt="HomeWithPeople" />
                    </div> */}
          {
            <DepositePremium>
              {Data?.property.elevation_premium && (
                <div className="premium_section">
                  <h4>Elevation Premium</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ConvertStringToHtml(
                        Data?.property.elevation_premium
                      ),
                    }}></div>
                </div>
              )}
              {Data?.property.description && (
                <div className="premium_section">
                  <h4>Lot Premium</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ConvertStringToHtml(Data?.property.description),
                    }}></div>
                </div>
              )}
            </DepositePremium>
          }
        </RightContainer>
        {appointment && (
          <AppointmentContainer onClick={() => setAppointment(!appointment)}>
            <AppointmentForm Data={Data} />
          </AppointmentContainer>
        )}
        <Appoinment className="mobileAnimation">
          <div className="animation">
            <div
              className="wrapper"
              onClick={() => setAppointment(!appointment)}>
              <img
                src={House}
                alt="House"
              />
            </div>
          </div>
        </Appoinment>
      </Container>
    </>
  );
}
