import styled from "styled-components";

export const LoaderContainer = styled.div`
 height: ${props => props.HeightVeriable}vh;
 display: flex;
 justify-content: center;
 align-items: center;
   .loader{
       border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
   }
  
`