/** @format */

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { transparentize } from 'polished';
export const Conatainer = styled.div`
  border-radius: 1.5625rem;
  background: #f7f7f7;
  overflow: hidden;
  min-height: 33rem;
  max-height: 33rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 0.6rem;
  filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
  .ImgContainer {
    position: relative;
    .new {
      text-align: center;
      position: absolute;
      top: 2%;
      left: 2%;
      border-radius: 1.5625rem;
      background: rgba(255, 255, 255, 0.65);
      backdrop-filter: blur(1px);
      padding: 0.25rem 0.5rem;
      color: #0061df;
      z-index: 1;
    }
    .Favourite {
      position: absolute;
      top: 2%;
      right: 2%;
      border-radius: 1.5625rem;
      background: rgba(255, 255, 255, 0.65);
      backdrop-filter: blur(1px);
      padding: 0.25rem;
      z-index: 1;
    }
    .slick-slider {
      .slick-dots {
        li {
          width: 7px;
          margin: 0 2px;
          button::before {
            color: #eef0f4;
            opacity: 1;
          }
          &.slick-active {
            button::before {
              color: #0061df;
            }
          }
        }
      }
      .slick-prev {
        top: 50%;
        left: 7px;
        z-index: 10;
        #card-arrows {
          fill: #0061df;
        }
      }
      .slick-next {
        top: 50%;
        right: 7px;
        #card-arrows {
          fill: #0061df;
        }
      }
      .slick-dots {
        bottom: 13px;
      }
      .slick-track {
        padding: 0;
      }
      .slick-list {
        max-height: 18rem;
        .slick-track {
          .slick-slide {
            overflow: hidden;
            img {
              min-height: 18rem;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    p {
      margin: 0;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .share {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .wrapperShare {
      filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
    }
  }
  .homeInfo {
    h1 {
      font-size: clamp(1rem, 1rem + 2vw, 2rem);
    }
  }
`;

export const SearchIcon = styled(Link)`
  position: relative;
  z-index: 100;
  width: 4.375rem;
  height: 4.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 100%;
  margin-top: -8rem;
  margin-bottom: 1rem;
  filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
`;

export const HomeInsideInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  margin: 1rem;
  padding-top: 0.75rem;
  margin-top: 0.75rem;
  .bed,
  .bath,
  .garage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.75rem;
  }
`;

export const BuilderPropertyCompany = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0061df;
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  gap: 1rem;
  .Heading {
    font-family: Inter;
    font-size: 18px;
    font-size: clamp(0.8rem, 1vw, 1.125rem);

    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
    margin-bottom: 0;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .SubHeading {
    font-family: Inter;
    font-size: 18px;
    font-size: clamp(0.8rem, 1vw, 1.125rem);

    font-weight: 400;
    /* line-height: 21.78px;*/
    text-align: left;
    margin-bottom: 0;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .builder_price {
    font-family: Inter;
    font-size: 2.375rem;
    font-size: clamp(1rem, 2vw, 2.375rem);
    font-weight: 400;
    /* line-height: 21.78px;*/
    text-align: left;
    margin-bottom: 0;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .left_section {
    flex: 0.3;
  }
  .divider {
    width: 0.11rem;
    height: 3.0625rem;
    gap: 0rem;
    border-radius: 0.625rem;
    background-color: white;
    display: block;
  }
  .right_section {
    flex: 0.7;
  }
`;

export const PropertyInfoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0;
  justify-content: space-between;
  padding-inline: 1.25rem;
  .property_info {
    margin: 0;
    font-size: 18px;
    font-size: clamp(0.8rem, 1vw, 1.125rem);
    font-weight: 500;
    line-height: 21.78px;
    text-align: left;
    font-family: Inter;
    color: #0061df;
  }
  .bed,
  .bath,
  .gar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    svg {
      display: block;
      path {
        fill: #0061df;
      }
    }
  }
`;

export const PropertyInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .price_address {
    width: 100%;

    .price {
      font-size: 2.375rem;
      font-size: clamp(1rem, 2vw, 2.375rem);
      font-weight: 700;
      line-height: 2.8744rem;
      margin-bottom: 0.25rem;
      color: grey;
      font-family: Inter;
    }
    .company {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      padding-left: 0.25rem;
      .property_name {
        margin: 0;
        font-family: Inter;
        font-size: 18px;
        font-size: clamp(0.8rem, 1vw, 1.125rem);
        font-weight: 500;
        line-height: 21.78px;
        text-align: left;
        color: gray;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding-inline: 1.25rem;
      }
      .property_company {
        margin: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding-inline: 1.25rem;
      }
    }
    .address {
      font-size: 1.125rem;
      font-size: clamp(0.8rem, 1vw, 1.125rem);
      font-weight: 400;
      line-height: 1.3613rem;
      overflow: hidden;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0;
      color: grey;
      font-family: Inter;
      padding-inline: 1.25rem;
      text-align: center;
    }
  }
  .search_icon {
    display: none;
    min-height: 4.5rem;
    min-width: 4.5rem;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #0061df;
      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;
