/** @format */

import React from 'react';
import { Container, FeatureContainer, Feature } from './Feature_Facts.style';
export default function FeatureFacts({ Data }) {
    const {
        building_status,
        home_type,
        building_type,
        city,
        community,
        bedroom,
        bathroom,
        basement,
        exterior,
        garage,
        property_tax,
        swimming_pool
    } = Data;
    return (
        <Container>
            <h1>Facts & Feature</h1>
            <FeatureContainer>
                <div className="lef_section">
                    {building_status ||
                        building_type ||
                        home_type ||
                        city ||
                        community ? (
                        <Feature>
                            <h1>Properties</h1>
                            {building_status && <p>Status:{building_status}</p>}
                            {home_type && <p>Type: {home_type}</p>}
                            {building_type && <p>Style:{building_type}</p>}
                            {city && <p>Area:{Data.city}</p>}
                            {community && <p>Community:{community}</p>}
                        </Feature>
                    ) : null}
                    {bathroom || bedroom ? (
                        <Feature>
                            <h1>Inside</h1>
                            {bedroom && <p>Bedrooms: {bedroom} </p>}
                            {/* <p>Beedrooms Plus: 2</p> */}
                            {bathroom && <p>Bathrooms:{bathroom}</p>}
                            {/* <p>Kitchen:2</p>
                        <p>Rooms:21</p>
                        <p>Room plus:0</p>
                        <p>Den Family Room:Y</p>
                        <p>Fireplace:Y</p> */}
                        </Feature>
                    ) : null}

                    {basement || exterior ? (
                        <Feature>
                            <h1>Building</h1>
                            {basement && <p>Basement:{basement}</p>}
                            {/* <p>Heating:forced Air</p>
                        <p>Water supply: Munciple</p> */}
                            {exterior && <p>Exterior:{exterior}</p>}
                        </Feature>
                    ) : null}
                    {garage ? (
                        <Feature>
                            <h1>Parking</h1>
                            {/* <p>Driveway:circular</p> */}
                            {garage && <p>Garage:{garage}</p>}
                            {/* <p>Parking Spaces:0</p>
                        <p>Total Parking:10.0</p>
                        <p>Converted Parking place : 0</p>
                        <p>Driveway:Circular</p> */}
                        </Feature>
                    ) : null}

                    {/* <Feature>
                        <h1>Suite</h1>
                        <p>Kitchen:0</p>
                    </Feature> */}
                </div>

                <div className="right_section">
                    {property_tax ? <Feature>
                        <h1>Fees</h1>
                        {property_tax && <p>Taxes:{property_tax}</p>}
                        {/* <p>Tax Year:2022</p>
                        <p>Tax Leagal Description:Tax Legal description not available</p> */}
                    </Feature> : null}

                    {/* <Feature>
                        <h1>Utilities</h1>
                        <p>Electricity:N</p>
                        <p>Gas:N</p>
                        <p>Cable:N</p>
                        <p>Telephone:N</p>
                    </Feature> */}

                    {/* <Feature>
                        <h1>HighLights</h1>
                        <p>Feature:N/A</p>
                        <p>Feature:N/A</p>
                        <p>Feature:N/A</p>
                        <p>Feature:N/A</p>
                        <p>Feature:N/A</p>
                    </Feature> */}

                    {swimming_pool ? <Feature>
                        <h1>Land</h1>

                        {/* <p>Fronting On:N/A</p>
                        <p>Frontage:45.12</p>
                        <p>Lot Depth:142.76</p>
                        <p>Lot Size:N/A</p>
                        <p>Acres:N/A</p> */}
                        {/* <p>Lot Irregularities:Pie Shape 141.98X55.12X142.76X45.12 </p> */}
                        {swimming_pool && <p>Pool:{swimming_pool}</p>}
                        {/* <p>Sewer:Sewers</p>
                        <p>Zoining:N/A</p>
                        <p>Parcel Number:N/A</p>
                        <p>Cross Street:Townline/adelaide</p>
                        <p>Municipality District: Oshawa</p> */}
                    </Feature> : null}

                </div>
            </FeatureContainer>
        </Container>
    );
}
