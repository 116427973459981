import React from 'react'
import { LoaderContainer } from './Loader.style'
export default function Loader({ Height }) {

    return (
        <LoaderContainer HeightVeriable={Height} >
            <div className="loader">

            </div>
        </LoaderContainer>
    )
}
