/** @format */

import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr minmax(0, 30%);
  gap: 1.5rem;
  @media screen and (max-width: 922px) {
    grid-template-columns: 1fr;
  }
`;

export const LeftContainer = styled.div``;

export const RightContainer = styled.div`
  align-self: flex-start;
  position: sticky;
  display: block;
  top: 80px;
  .ACard {
    width: inherit;
    padding: 1.5rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2rem;
    background: #eaf3ff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    h1 {
      color: #212121;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
    p {
      color: #575f6b;
      font-family: Inter;
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
    }
  }
  @media screen and (max-width: 922px) {
    display: none;
  }
`;

export const OverView = styled.div`
  padding-bottom: 3rem;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
  }
  .InfoContainer {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    .Info {
      h1 {
        color: #6e6e73;
        font-family: Inter;
        font-size: 0.875rem;
        font-size: clamp(0.65rem, 0.75rem + 1vw, 0.875rem);
        font-weight: 400;
      }
      h3 {
        color: #323232;
        font-family: Inter;
        font-size: 1rem;
        font-size: clamp(0.75rem, 0.75rem + 1vw, 1rem);
        font-weight: 400;
      }
    }
  }
  .Description {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    height: ${(props) => (props.Check ? '5rem' : '100%')};
    overflow: hidden;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 85%, 0.2)
    );
  }
  .Wrapper {
    width: max-content;
    border-radius: 1rem;
    border: 1px solid #d1d1d1;
    background: #fff;
    padding: 0.25rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    margin: -1rem auto 0 auto;
    .SvgWrapper {
      transform: rotate(${(props) => (props.Check ? '0deg' : '180deg')});
    }
    p {
      margin: 0;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Map = styled.div`
  border-top: 2px solid #e1e1e1;
  border-bottom: 2px solid #e1e1e1;
  padding: 4rem 0;
  .Container {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
  }
  .leftSection {
    flex: 1;
    iframe {
      width: 100%;
      height: 100%;
      min-height: 15rem;
      border-radius: 0.625rem;
    }
  }
  .rightSection {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    flex: 1;
    .wrapper {
      margin: auto;
      width: 3rem;
      height: 3rem;
      background-color: #fff;
      border-radius: 50%;
      filter: drop-shadow(0px 2px 5px rgba(0, 97, 223, 0.2));
      display: flex;
      justify-content: center;
      align-items: center;
    }
    p {
      margin: 0.75rem 0 0 0;
    }
  }
  @media screen and (max-width: 1160px) {
    .Container {
      flex-direction: column;
      .leftSection,
      .rightSection {
        width: 100%;
      }
    }
  }
`;

export const Amenities = styled.div`
  padding: 4rem 0;
  border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
        font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
    margin-bottom: 2rem;
  }
  .Container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 1.5rem;
    .wrapper {
      margin: auto;
      width: 3rem;
      height: 3rem;
      background-color: #fff;
      border-radius: 50%;
      filter: drop-shadow(0px 2px 5px rgba(0, 97, 223, 0.2));
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const PropertyDetails = styled.div`
   padding: 4rem 0;
 border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-family: Inter;
    font-size: 1.25rem;
        font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
  }
  .Details {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1.5rem;

    span {
      color: #13131a;
      font-weight: 500;
    }
  }
`;
export const Appoinment = styled.div`
  border-radius: 3.125rem 1.25rem 1.25rem 3rem;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  gap: 1.5rem;
  &.mobileAnimation {
    display: none;
    background: none;
  }
  p {
    margin: 0;
    color: #212121;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    margin-left: 1rem;
  }
  .animation {
    .wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0061df;
      border-radius: 50%;
      padding: 0.3rem;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px solid #3498db;
        border-radius: 50%;
        animation: wave 2s linear infinite;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 10px solid #3498db;
        border-radius: 50%;
        animation: wave 1s linear infinite;
      }
      @keyframes wave {
        0% {
          transform: scale(1);
          opacity: 1;
        }
        100% {
          transform: scale(2);
          opacity: 0;
        }
      }
    }
   
  }
  &:hover {
    background: linear-gradient(
      271deg,
      #0061df 15.89%,
      rgba(0, 97, 223, 0) 98.9%
    );
    .content {
      color: #ffff;
    }
  }
   @media screen and (max-width: 925px) {
      &.mobileAnimation {
        display: block;
        position: sticky;
        bottom: 3rem;
        width: fit-content;
        margin-left: auto;
        box-shadow: none;
      }
    }
`;

export const AvailableFloorPlans = styled.div`
   padding: 4rem 0;
   border-bottom: 2px solid #e1e1e1;
  h1 {
    color: #13131a;
    font-size: 1.25rem;
    font-family: Inter;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
    font-weight: 500;
  }
  .FloorPlansContainer {
    display: flex;
    flex-direction: column;
  }
`;
export const AppointmentContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  display: block;
  z-index: 10000;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const DepositePremium = styled.div`
    background-color: #eaf3ff;
    border-radius: 0.52rem;
    padding: 1rem;
    margin-top: 2rem;
  .premium_section{
      h4{
      text-align: center;
      color: #0061df;
     }
  }
`
export const MobileDepositePremium = styled.div`
    display: none;
    background-color: #eaf3ff;
    border-radius: 0.52rem;
    padding: 1rem;
    margin: 2rem 0;
  .premium_section{
      h4{
      text-align: center;
      color: #0061df;
     }
     }
   @media screen and (max-width: 922px) {
    display: block;
  }
`
export const DepositeStructure = styled.div`
   padding: 4rem 0;
   border-bottom: 2px solid #e1e1e1;
   h2 {
    font-family: Inter;
    font-size: clamp(1rem, 1rem + 1vw, 2.25rem);
  }
  .DepositeStructure{
     display: grid;
     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
     padding: 1.4rem;


  }
`