import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20rem;
    & .error_screen{
        max-width: 20rem;
        filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
    }
`