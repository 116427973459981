/** @format */

import React from 'react';
import HeroHome from '../components/HeroHome/HeroHome.component';
import BuilderMatchHelpSection from '../components/RedBuyersHelpSection/RedBuyersHelpSection.component';
import Featured from '../components/Featured/Featured.component';
export default function Home() {
    return (
        <>
            <HeroHome />
            <BuilderMatchHelpSection />
            <Featured />
        </>
    );
}
