import React, { useState, useContext } from 'react'
import { FormContainer } from './ShareForm.style'
import axios from 'axios'
import { SharePropertyContext } from '../../Context/ShareProperty.context'
import Loader from '../Loader/Loader.components'
export default function ShareForm() {
    const { state, Reset } = useContext(SharePropertyContext)
    const [loader, setloader] = useState('form')
    const [EmailData, setEmailData] = useState({ sender_email: '', receivers: [] })
    const [Emails, setEmails] = useState('')
    const handleReciverEmails = () => {
        setEmailData({ ...EmailData, receivers: [...EmailData.receivers, Emails] })
        setEmails('')
    }
    const Share = async (e) => {
        e.preventDefault()
        setloader('loader')
        const { sender_email, receivers } = EmailData

        const { propertyList } = state
        if (!propertyList?.length || !receivers.length) return;

        const formData = new FormData();
        formData.append("sender_email", sender_email);

        for (let [index, email] of Object.entries(receivers))
            formData.append(`users_email[${index}]`, email);

        for (let [index, property] of Object.entries(propertyList)) {
            formData.append(`property[${index}][link]`, property.link);
            formData.append(`property[${index}][address]`, property.address);
            formData.append(`property[${index}][beedroom]`, property.bedroom);
            formData.append(`property[${index}][bathroom]`, property.bathroom);
            formData.append(`property[${index}][garage]`, property.garage);
            formData.append(`property[${index}][price]`, property.price);
        }

        try {
            await axios.post("https://www.redbuyers.com/api/share-property", formData)
            setloader('submitted')
            Reset()
        } catch (err) {
            setloader('error')
        }
    }
    return (
        <FormContainer onClick={(event) => event.stopPropagation()} onSubmit={Share}>
            {loader === 'form' && <>
                <h3>Share</h3>
                <input type='email' required value={EmailData.sender_email} onChange={(e) => setEmailData({ ...EmailData, sender_email: e.target.value })} placeholder='Sender Email' className='Email Input' />
                <div className="Reciver">
                    <input type='email' value={Emails} onChange={(e) => setEmails(e.target.value)} placeholder={`Receiver's Email's`} className='ReciveEmails Input' />
                    <div className='EmailsButton' onClick={handleReciverEmails}>
                        Add
                    </div>
                </div>
                {EmailData.receivers.length !== 0 && EmailData.receivers.map((data, i) => <div key={i} className='Emails'>
                    <p>{data}</p>
                </div>)}
                <button>
                    Schedule Appointment
                </button>
            </>}
            {loader === 'loader' && <Loader />}
            {loader === 'submitted' && <h1>Completted</h1>}
            {loader === 'error' && <h1>something went wrong</h1>}
        </FormContainer>
    )
}
