
import './App.css';
import { Route, BrowserRouter, Routes, } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar.component';
import Home from './page/home.page';
import NewConstructionl from './page/NewConstructionl.page';
import HomeDetail from './page/HomeDetail.page';
function App() {
  return (
    <BrowserRouter>
      <Routes >
        <Route path='/' element={<Navbar />}>
          <Route index element={<Home />} />
          <Route path='new-construction' element={<NewConstructionl />} />
          <Route path='new-construction/:id' element={<HomeDetail />} />
          <Route path='featured-homes/:id' element={<HomeDetail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
