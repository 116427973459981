/** @format */

import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { NewConstructionContext } from '../../Context/Construction.context';
import Filter from '../Filter/Filter.component';
import PropertyCard from '../PropertyCard/PropertyCard.component';
import { PropertyCards, Container } from './NewConstruction.style';
import Peginate from '../Peginate/Peginate.component';
import NoResult from './Images/No result.gif';
import Loader from '../Loader/Loader.components';

import { setImageBanner } from '../../utils/New_construction_details/setImageBanner';

export default function NewConstruction() {
    const { state } = useContext(NewConstructionContext);
    const {
        city,
        page,
        bathroom,
        bedroom,
        building_status,
        home_type,
        min_price,
        max_price,
        search,
        sortBy,
        sortDir
    } = state;
    const [property, setProperty] = useState(null);
    const [link, setLink] = useState();
    const [loader, setLoader] = useState('loading');
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        const source = axios.CancelToken.source();
        const fetchData = async () => {
            setLoader('loading');
            try {
                const response = await axios.get(
                    `https://www.redbuyers.com/api/new-construction`,
                    {
                        cancelToken: source.token,
                        params: {
                            min_price: min_price,
                            max_price: max_price,
                            home_type: home_type,
                            building_status: building_status,
                            bedroom: bedroom,
                            bathroom: bathroom,
                            sortBy: sortBy,
                            sortDir: sortDir,
                            city: city,
                            page: page,
                            search: search,
                        },
                    }
                );
                const data = response.data.data.property.data;

                const link = response.data.data.property;
                setLink(link);
                setProperty(data);
                setLoader('idle');
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Fetching data with axios was cancelled');
                } else {
                    throw error;
                }
            }
        };
        fetchData();
        return () => {
            source.cancel('Operation canceled by cleanup.');
        };
    }, [
        page,
        city,
        bedroom,
        bathroom,
        building_status,
        home_type,
        min_price,
        max_price,
        search,
        sortDir,
        sortBy
    ]);
    return (
        <Container>
            <Filter />
            {loader === 'loading' && <Loader Height={100} />}
            {loader === 'idle' && (
                <div className="main">
                    <div className="HomeCount">
                        <h1>New Homes For Sale</h1>
                        <p>{link?.total} homes available on BuilderMatch</p>
                    </div>
                    <div className="MainCards">
                        {property === null && <div class="loader"></div>}
                        <PropertyCards>
                            {property === null
                                ? ''
                                : property
                                    .filter((item) => !item.is_parent)
                                    .map((data, i) => (
                                        <PropertyCard
                                            Data={data}
                                            key={i}
                                        />
                                    ))}
                        </PropertyCards>
                        {property?.length === 0 && (
                            <img
                                src={NoResult}
                                alt="noresult"
                            />
                        )}
                    </div>
                    <Peginate
                        forcePage={page}
                        pageCount={link && link.last_page}
                    />
                </div>
            )}
        </Container>
    );
}
