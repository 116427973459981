/** @format */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Container = styled.div`
  width: min(80rem, 95%);
  margin: 0 auto;
  margin-top: 6rem;
`;
export const FooterLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
  border-bottom: 1px solid #6e6e73;
  p {
    color: #0061df;
    font-family: Inter;
    font-size: 1rem;
    font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
`;

export const FooterLink = styled(Link)`
  display: block;
  color: #6e6e73;
  font-family: Inter;
  font-size: 1rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  padding-bottom: 1rem;
`;
export const FooterNlink = styled(Link)`
  display: block;
  color: #0061df;
  font-family: Inter;
  font-size: 1rem;
  font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
  font-weight: 500;
  padding-bottom: 1rem;
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 0.25rem;
  background: #0061df;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 0.63rem 1.25rem;
  p {
    color: #fff;
    text-transform: capitalize;
    margin: 0;
    margin-left:.40rem;
  }
`;

export const ContactUs = styled.div`
  margin-top: 3rem;
  p {
    color: #0061df;
    font-family: Inter;
    font-size: 1rem;
    font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
  .contact {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    p {
      color: #6e6e73;
      font-weight: 400;
    }
  }
  .CopyRight{
    font-weight: 700;
    color: #6E6E73;
  }
  .Act{
    font-weight: 400;
    color: #6E6E73;
  }
`;
