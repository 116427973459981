import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Container, Logo, NavLink, Login, Register, MobileLinks } from './Navbar.style'
import LogoImage from './Images/Logo.png'
import Footer from '../Footer/Footer.component'
import { useLocation } from 'react-router-dom';
export default function Navbar() {
    const [hamIcon, sethamIcon] = useState(false);
    const location = useLocation();
    const toggleNavbar = () => {
        sethamIcon(!hamIcon);
    };
    return (
        <>
            <Container>
                <div className="main">
                    <div className="Section1">
                        <div className="HamBurggerMenue" onClick={toggleNavbar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="35" viewBox="0 0 34 35" fill="none">
                                <path d="M5.84375 10.5938H28.1562M5.84375 17.5H28.1562M5.84375 24.4062H28.1562" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
                            </svg>
                        </div>
                        <Logo to='/'>
                            {/* <img src={LogoImage} alt="LogoImage" /> */}
                            <h3>BuilderMatch</h3>
                        </Logo>
                    </div>
                    <div className="Section2">
                        <NavLink Check={location.pathname === '/'} to='/'> Home </NavLink>
                        <NavLink Check={location.pathname === '/contact'} to='/'> Contact </NavLink>
                    </div>
                    <div className="Section3">
                        <Login>Login</Login>
                        <Register>Register</Register>
                    </div>
                </div>
                <MobileLinks open={hamIcon}>
                    <div>
                        <NavLink
                            to="/about-us"
                            onClick={toggleNavbar}>
                            Home
                        </NavLink>
                        <NavLink
                            to="/about-us"
                            onClick={toggleNavbar}>
                            Contact
                        </NavLink>
                        <a href="#contact-form" onClick={toggleNavbar}>contact us</a>
                    </div>
                </MobileLinks>
            </Container>
            <Outlet />
            <Footer />
        </>

    )
}
