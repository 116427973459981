/** @format */

import styled from 'styled-components';
export const Container = styled.div`
  width: min(90rem, 95%);
  margin: 5rem auto;
  .ScrollUpArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 4%;
    right: 2%;
    background-color: #0061df;
    border-radius: 0.4rem;
    padding: 0.52rem;
    opacity: ${(props) => (props.ScrollValue > 15 ? 1 : 0)};
    transition: opacity 0.8s ease-in-out;
    z-index: 1000;
  ;
  }
  .no_slider {
    display: none;
  }
  .Slider {
    position: relative;
    overflow: hidden;
    filter: drop-shadow(0px 0px 5px rgba(44, 54, 71, 0.2));
    .slider_button {
      position: absolute;
      bottom: 2%;
      right: 3%;
      border-radius: 0.625rem;
      background: #fff;
      background-color: #ffffff;
      color: #0061df;
      padding: .63rem;
      cursor: pointer;
      p{
        margin: 0;
      }
    }
    .slick-dots {
      bottom: 8px;
    }
    .slick-prev {
      left: 3%;
      z-index: 100;
    }
    .slick-next {
      right: 3%;
    }
    .slick-slide {
      padding: 0 1rem;
      img {
        border-radius: 1.5625rem;
        overflow: hidden;
        width: 100%;
        object-fit: fill;
        max-height: 43rem;
        border-radius: .63rem + 0.625rem ;
      }
    }
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
  a {
    text-decoration: none;
    color: #6e6e73;
    font-size: clamp(1rem, 1rem + 1vw, 1rem);
  }
  p {
    color: #0061df;
    font-size: clamp(1rem, 1rem + 1vw, 1rem);
    margin: 0;
  }

  @media screen and (max-width: 786px) {
    display: none;
  }
`;

export const Content = styled.div`
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  & .d-bars {
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
  }
  & .o-bars {
    border-right: 1px solid #d9d9d9;
  }
  .Info {
    text-align: center;
    flex: 1;
    /* padding: 0 8rem; */
    h3 {
      color: #0061df;
      font-family: Inter;
      font-size: 1.5rem;
      font-size: clamp(1rem, 1rem + 1vw, 1.5rem);
      font-weight: 500;
      margin: 0;
    }
    p {
      color: #13131a;
      font-family: Inter;
      font-size: 1rem;
      font-size: clamp(0.75rem, 0.75rem + 1vw, 1rem);
      font-weight: 500;
      margin: 0;
    }
    &.first {
      padding-left: 0;
    }
  }
  .Price {
    margin-left: auto;
    h1 {
      color: #0061df;
      font-family: Inter;
      font-size: 3rem;
      font-size: clamp(1.5rem, 1rem + 2vw, 3rem);
      font-weight: 600;
    }
  }
  @media screen and (max-width: 825px) {
    flex-direction: column;
    & .d-bars {
      border: none;
    }
    & .d-bars {
      border: none;
    }
  }
`;

export const NavContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.5rem;
  a {
    color: #6e6e73;
    font-family: Inter;
    font-size: 1rem;
    font-size: clamp(0.75rem, 1rem + 1vw, 1rem);
    font-weight: 500;
    text-decoration: none;
    position: relative;
    &:hover::after {
      position: absolute;
      content: '';
      bottom: -20%;
      left: 0;
      width: 100%;
      height: 0.3rem;
      background-color: #0061df;
    }
    &:hover {
      color: #0061df;
    }
  }
`;

export const FeaturedContainer = styled.div`
  margin-top: 6rem;
  .FeaturedContainer {
    position: relative;
    .slick-prev {
      top: 110%;
      left: 90%;
      z-index: 100;
    }
    .slick-next {
      top: 110%;
      right: 0;
    }
    .slick-slide {
    }
    .Cards {
      p {
        margin: 0;
      }
    }
  }
`;

export const AppointmentContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  display: block;
  z-index: 10000;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;
