
import { ConvertStringToHtml } from "./ConvertDepositeToHtml";

export const DepositeDescription = (Data) => {
    const homeType = Data?.home_type?.toString();
    const ParentDeposite = Data?.plan_properties[0]?.detailed_description;
    const ChildDeposite = Data?.deposit_structure;
    const properties = ["Detached", "Semi-detached", "Town House"]
    let DepositeArray;
    if (properties.includes(homeType) && ChildDeposite) {
        DepositeArray = ChildDeposite.split("\n\n");
    } else {
        DepositeArray = ParentDeposite.split("\n\n");
    }

    let DepositeSection = "";

    if (DepositeArray) {
        for (let section of DepositeArray) {
            DepositeSection += `<div>${ConvertStringToHtml(section)}</div>`;
        }
        return DepositeSection;
    }
}