/** @format */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PropertyCard from '../PropertyCard/PropertyCard.component';
import CustomBar from '../CustomBar/CustomBar.component';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container, PropertyCards, Button } from './Featured.style';
export default function Featured() {
    const [Property, setProperty] = useState()
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);
        // Cleanup
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);
    useEffect(() => {
        const source = axios.CancelToken.source();
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `https://www.redbuyers.com/api/new-construction`,
                    {
                        cancelToken: source.token,
                    }
                );
                const data = response.data.data.property.data.slice(0, 6);
                setProperty(data);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Fetching data with axios was cancelled');
                } else {
                    throw error;
                }
            }
        };
        fetchData();
        return () => {
            source.cancel('Operation canceled by cleanup.');
        };
    }, [])
    const FeatureCardSetting = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => setCurrentSlide(next),
    }
    return (
        <Container>
            <div className="Content">
                <h1>Featured</h1>
                <p>Check out our upcoming Partner Projects</p>
            </div>
            <PropertyCards>
                {Property && !isSmallScreen && Property.map((data) => <PropertyCard Data={data} key={data.id} />)}
            </PropertyCards>
            {isSmallScreen && <div className='SliderContainer'><Slider {...FeatureCardSetting}>
                {Property && Property.map((data) => <PropertyCard Data={data} key={data.id} />)}
            </Slider>
                <CustomBar currentSlide={currentSlide} totalSlides={6} />
            </div>
            }
            <Button to='new-construction'>
                View All
            </Button>
        </Container>
    );
}
