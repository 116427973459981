import React, { createContext, useReducer, } from 'react';

const initialState = {
    markCheck: false,
    propertyList: [],
}

const ShareReducer = (state, action) => {
    switch (action.type) {
        case 'mark_check':
            return { ...state, markCheck: action.payload }
        case 'property_list':
            return { ...state, propertyList: action.payload }
        default: return state
    }
}

const SharePropertyContext = createContext()

const SharePropertyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ShareReducer, initialState)
    const Check = check => {
        dispatch({ type: 'mark_check', payload: check })
    }
    const AddProperty = property => {
        let updateSelectedCard;
        let includesObject = state.propertyList.some(item => {
            return item.id === property.id;
        });
        if (includesObject) {
            updateSelectedCard = state.propertyList.filter((Data) => Data.id !== property.id)
            if (updateSelectedCard.length === 0) {
                Check(!state.markCheck)
            }
        } else {
            updateSelectedCard = [...state.propertyList, property]
        }
        dispatch({ type: 'property_list', payload: updateSelectedCard })
    }
    const Reset = () => {
        dispatch({ type: 'property_list', payload: [] })
    }

    const contextValue = {
        state,
        Check,
        AddProperty,
        Reset
    }
    return <SharePropertyContext.Provider value={contextValue}>
        {children}
    </SharePropertyContext.Provider>
}

export { SharePropertyContext, SharePropertyProvider }